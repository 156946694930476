/* Laptops */
/* @media (min-width: 769px) and (max-width: 1024px) {} */

/* Tablets */
/* @media (min-width: 481px) and (max-width: 768px) {} */

/* Mobile devices */
/* @media (min-width: 320px) and (max-width: 480px) {} */

/* 
    Colors:
    #E2725B
    #94B0DA
    #495159
*/

body,
html {
    max-width: 100%;
    overflow-x: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
div {
    color: #495159 !important;
}

/* Home Page */

.home-page-main-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding-bottom: 100px;
}

.home-page-welcome-box {
    /* The image used */
    background-image: url("./assets/home-opacity.png");

    /* Full height */
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;

    /* Alignment */
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    /* border-bottom: 3px solid #9dbcff35; */
}

.home-page-welcome-box a {
    margin: auto;
}

.homepage-title {
    font-size: 50px;
}

/* Experience.js */
.experience-title-text {
    color: #94B0DA !important;
    font-weight: bolder !important;
}


/* StepsCard component */
.motto-left-decoration {
    display: flex;
    margin-top: -300px;
    margin-bottom: -300px;
}

.steps-cards-box svg {
    color: #94B0DA !important;
}

.steps-cards-title-span-box {
    font-weight: bold;
    text-decoration: underline;
    color: #e2725b;
}


/* Knowledge.js */

/* About Us Page */

.about-us-main-box {
    /* The image used */
    background-color: #64afcd00;
    background-image: url("./assets/about-us-opacity.png");

    /* Full heigvht */
    width: 100vw;
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    /* Alignment */
    display: flex;
    flex-direction: column;
    text-align: right;
    /* justify-content: center; */
}

/* Gallery.js */
.gallery-profile-photo {
    width: 300px;
    height: 400px;
    /* margin-top: 30px; */
    border-radius: 25%;
}

/* ContactUs.js */
#form {
    width: 400px;
}

form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
}

input {
    width: 100%;
    height: 35px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
}

input:focus {
    border: 2px solid rgba(0, 206, 158, 1);
}

textarea {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
}

textarea:focus {
    border: 2px solid rgba(0, 206, 158, 1);
}

label {
    margin-top: 1rem;
}

input[type="submit"] {
    margin-top: 2rem;
    cursor: pointer;
    background: #0e93f9;
    color: white;
    border: none;
}

input[type="submit"]:hover {
    background: #0e93f9ba;
}

/* ContactUsForm.js */
.contact-us-form-component-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin: auto;
}

/* ContactUs.js */

.contact-us-page-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-image: url("./assets/contact-us-opacity.png");
    /* Full height */
    width: 100%;
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


/* MEDIA QUERIES */
/* Laptops */
@media (min-width: 769px) and (max-width: 1024px) {

    /* Experience.js */
    .experience-main-box {
        display: flex;
        flex-direction: column !important;
        align-self: center;
        margin-bottom: 50px;
        flex-direction: column-reverse !important;
    }

    .experience-organisations-box {
        display: flex;
        flex-direction: column !important;
    }

    .experience-years-box {
        margin-bottom: 50px !important;
    }

    /* StepsCards.js */
    .steps-cards-box {
        display: flex;
        /* flex-direction: column !important; */
        justify-content: center;
        text-align: center;
    }

    .steps-cards-paper {
        margin: 20px 100px;
    }

    .about-us-main-box {
        /* The image used */
        background-color: #64afcd00;
        background-image: url("./assets/about-us-opacity.png");

        /* Full heigvht */
        width: 100vw;
        height: 100vh;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        /* Alignment */
        display: flex;
        flex-direction: column;
        text-align: right;
        /* justify-content: center; */
    }
}

/* Tablets */
@media (min-width: 481px) and (max-width: 768px) {

    /* Experience.js */
    .experience-main-box {
        display: flex;
        flex-direction: column !important;
        align-self: center;
        margin-bottom: 50px;
        flex-direction: column-reverse !important;
    }

    .experience-organisations-box {
        display: flex;
        flex-direction: column !important;
    }

    .experience-years-box {
        margin-bottom: 50px !important;
    }

    /* StepsCards.js */
    .steps-cards-title-span-box {
        margin: auto 20px;
    }

    .steps-cards-box {
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        text-align: center;
    }

    .steps-cards-paper {
        margin: 20px 100px;
    }

    /* Knowledge.js */
    .knowledge-main-box {
        display: flex;
        flex-direction: column;
    }

    #form {
        width: 300px;
        margin: 0 !important;
        padding: 0 !important;
    }

    .about-us-main-box {
        /* The image used */
        background-color: #64afcd00;
        background-image: url("./assets/about-us-opacity.png");

        /* Full heigvht */
        width: 100vw;
        height: 100vh;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        /* Alignment */
        display: flex;
        flex-direction: column;
        text-align: right;
        /* justify-content: center; */
    }
}

/* Mobile devices */
@media (min-width: 320px) and (max-width: 480px) {

    /* Experience.js */
    .experience-main-box {
        display: flex;
        flex-direction: column !important;
        align-self: center;
        margin-bottom: 50px;
        flex-direction: column-reverse !important;
    }

    .experience-organisations-box {
        display: flex;
        flex-direction: column !important;
    }

    .experience-years-box {
        margin-bottom: 50px !important;
    }

    /* StepsCards.js */
    .steps-cards-box {
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        text-align: center;
    }

    .steps-cards-paper {
        margin: 20px 100px;
    }

    /* Knowledge.js */
    .knowledge-main-box {
        display: flex;
        flex-direction: column;
    }

    #form {
        width: 300px;
        margin: 0 !important;
        padding: 0 !important;
    }

    .about-us-main-box {
        /* The image used */
        background-color: #64afcd00;
        background-image: url("./assets/about-us-opacity.png");

        /* Full heigvht */
        width: 100vw;
        height: 100vh;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        /* Alignment */
        display: flex;
        flex-direction: column;
        text-align: right;
        /* justify-content: center; */
    }
}

/*  */